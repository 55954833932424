*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, label, p{
    color: rgb(66, 54, 109);
}

#sidebar {
    position: absolute;
    width: 40%;
    height: 100%;
    background: #000;
    left: -300px;
    transition: .4s;

}

#sidebar.active{
    left: 0;
    z-index: -1;

}
#sidebar ul li {
    list-style: none;
    color: #fff;
    font-size: 20px;
    padding: 20px 24px;

}

#sidebar .togglebutton {
    position: absolute;
    top: 30px;
    left: 330px;
}

.togglebutton span {
    width: 45px;
    height: 4px;
    background: #000;
    display: block;
    margin-top: 4px;

}

.container{
    min-width: -webkit-fill-available;
    padding: 0%;
}

main {
    padding: 1%;
    margin-bottom:1%;
    padding-left: 4%;

}
/* 
aside {
    z-index: -5;
    margin: 10% 10%;
} */

.picturesOfHouses {
    z-index: -3;
}

.enquiry {
    margin-left: 8%;
    width: 50%;
    padding: 3%;
    background: rgb(250,238,212)
}

.enquiry input[type=text], input[type=tel], input[type=email] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    
    
}

textarea{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    background-color: rgb(115,75,255);
    width: 40%;
    color: rgb(66, 54, 109);
  }
  
img {
    height: 300px;
    padding: 10px;
    width: 400px;
    z-index: -5;
}

.information li{
    list-style: none;
}

.informationcontainer article{
    float: left;
}
.houseCarousel{
    width: 50%;
    z-index: -8;    
}

.pictures{
    padding: 2px;
    margin-block: inherit;
}
.Contact_information{
    background-color: rgb(226,233,238);
    padding: 10%;
}

.flex-container {
    display: flex;
    flex-wrap: nowrap;
    background-color: EDEAE5;
  }
  
  .flex-container .box {
    background-color:#e6f0ff;
    width: 50%;
    margin: 10px;
    text-align: justify;
    line-height: 75px;
    font-size: 18px;
    color: rgb(66, 54, 109);
    padding: 21px;
    line-height: normal;
    
  }

  .flex-container .box h4 {
    padding: 2px;
    
  }
  .form-container{
    display: inline-block;
  }

  .form-container h5 {
    margin-bottom: 25px;
  }

 .form {
    width: 60%;
    display: block;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 15px;
    background-color: #F1F7FF;
  }

  .nav-text svg {
    color: #03017b;
  }

  .checbox-form>label{
    display: inline;
  }

  .form label{
    font-weight: 500;
    font-size: large;
  }
  ::placeholder {
    color: lightgrey;
  }

 label, input[name="submit"] {
    position: relative;
  }
  label > span, input, textarea, button {
    box-sizing: border-box;
  }
  /* label {
    display: block;
  } */
  label > span {
    display: none;
  }
  input, textarea, button {
    width: 100%;
    padding: 0.5em;
    border: none;
    font: 300 100%/1.2 Ubuntu, sans-serif;
  }
  input[type="text"], input[type="email"], textarea {
    margin: 0 0 1em;
    border: 1px solid #ccc;
    outline: none;
  }

  textarea {
    height: 6em;
  }
  input[type="submit"], button {
    background:#99c2ff;
    color: #03017b;
  }
  input[type="submit"]:hover, button:hover {
    background: #8180BC;
  }

/* .housepictures{
    float: right;
    width: 35%;
} */

footer{
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    text-align: center;
    background: rgb(3,1,123);
    background: linear-gradient(180deg, #99c2ff 0%, #99c2ff 50%, rgba(255,255,255,1) 100%);
    text-align: center;
    height: 30px;
    color: rgb(255,255,255);
}

.relatedimage{
    float: right;
    justify-content: flex-end;

}

.information{
    display: inline-flex;
    width: 100%;
}
.information p{
    float: left;
    padding: 0;
}

.photo-apt{
   margin-top: 25px;
}

.photo-apt img{
  height: 50%;
  padding: 10px;
  width: 75%;
  z-index: -5;
}

.downloadable_pdfs button {
  margin: 2%;
  text-align: center;
  color: #03017b;
  box-shadow: 1px 1.5px #03017b;
}
  
.downloadable_pdfs button {
    margin: 2%; 
    text-align: center;
    /* background-color: #03017b; */
    color:#ffffff;
  }

  .downloadable_pdfs button i{
    font-size: 25px;
    font-style: normal;
  }

  .downloadable_pdfs i{
    font-size: 25px;
    font-style: normal;
    color: #03017b;
  }


 

.information_app{
    display: inline-flex;
    list-style: none;

}

.orientation_button {
  text-transform: none;
  height: 50px;
  margin: 10px;
  box-shadow: 1.5px 1.5px #03017b;
}

.grid-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.item-1{
  text-align: justify;
  font-size: 1em;
  font-weight: 500;
}

.orientation-header{
  margin-left: 10px;
}

.startword{
  font-size: 40px;
  font-weight: 800;
  font-style: oblique;
}


.checkboxdiv {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  size: 30px;
  box-size: 30px;
  block-size: 30px;
  float: right;
}

.checkboxdiv label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  
}
.checkboxdiv input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
  overflow: hidden;
  block-size: 30px;
}

#g-recaptcha {
  display: inline-block;
  padding-left: 20%

}

.checkboxdiv input {
  width: 25px;
  height: 20px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
   overflow: hidden;
  size: 30px;
  size: unset;

}

Recaptcha{
  display: inline;
}

.availableapts{
  background-color: #e6f0ff;
  padding: 2%;
}

.availableapts p{
 
}


.availableapts h3 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}