.navbar-container{
    margin: 0%;
    padding: 0%;
}

root{
    margin: 0%;
    padding: 0%;
}
.navbar {
    height: 80px;
    display: flex;
    align-items: center;

  }

.navbar-container{
  background-color:#99c2ff;
}
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color:#cce0ff;
    width: 340px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    /* border-style: solid;
    border-color:rgb(66,54,109);   */
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 2;
  }

  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
    color: rgb(66,54,109);
  }
  
  .nav-text a {
    text-decoration: none;
    color: #03017b!important;
    font-size: 20px;
    font-weight: 600;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 18px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #99c2ff;
    color:#03017b!important;
  }
  
  .nav-text svg {

    color:#03017b!important;
  }
  
  .nav-menu-items {
    width: 100%;

  }
  
  .navbar-toggle {
    background-color:#cce0ff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
  }

  
  span {
    margin-left: 16px;
  }




